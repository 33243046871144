.footer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.footer-text:focus-visible {
  outline: lightgreen solid 2px;
}

.footer {
  display: flex;
  box-sizing: border-box;
  height: 100px;
  width: 95%;
  background-color: var(--header);
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 2px rgba(51, 51, 51, 0.41);
}

.footer-alt {
  display: flex;
  box-sizing: border-box;
  height: 100px;
  width: 95%;
  background-color: #6c7073;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 2px rgba(51, 51, 51, 0.41);
}

@media only screen and (max-width: 700px) {
  .footer-text {
    display: none;
  }
}
