.togglePic {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 240px;
}
.togglepic:focus-visible {
  outline: lightgreen solid 2px;
}
.logocat {
  content-visibility: auto;
}
.headerClip {
  margin-bottom: 30px;
}
.bubble {
  position: absolute;
  margin: -165px 0px 0px 200px;
  max-width: 30em;
  font-family: "Mate";
  background-color: var(--whiteSmoke);
  padding: 0.5em 1em;
  font-size: 1.25em;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
}
.bubble::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0.8em;
  border: 0.75rem solid transparent;
  border-top: none;
  border-bottom-color: var(--whiteSmoke);
  filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .togglePic {
      margin-left: 60%;
    }
    @media screen and (max-width: 1900px) {
      .togglePic {
        margin-left: 240%;
      }
    }
    @media screen and (max-width: 1200px) {
      .togglePic {
        margin-left: 200%;
      }
    }
    @media screen and (max-width: 1000px) {
      .togglePic {
        margin-left: 160%;
      }
    }
    @media screen and (max-width: 600px) {
      .togglePic {
        margin-left: 180%;
      }
    }
  }
}
