/* HEADER */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: -20px;
  z-index: 1;
}

.switch {
  display: flex;
  box-sizing: border-box;
  margin-right: 80%;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.theme-btn {
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 40%;
  position: absolute;
}

.theme-btn:focus-visible {
  outline: lightgreen solid 2px;
}

.theme-btn:hover {
  transform: scale(1.1);
}

.drop {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  place-items: center;
  box-sizing: border-box;
  margin-left: 78%;
  margin-top: 1%;
}

.header {
  cursor: pointer;
  display: flex;
  place-content: center;
  place-items: center;
}

.logo {
  padding: 4px;
  border-radius: 10px;
  filter: drop-shadow(0 0 0.3rem var(--gray));
  content-visibility: auto;
}

.menu {
  display: flex;
  box-sizing: border-box;
  height: 150px;
  width: 95%;
  background-color: var(--header);
  margin-top: 10px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 2px rgba(51, 51, 51, 0.41);
}

.menu-alt {
  display: flex;
  box-sizing: border-box;
  height: 150px;
  width: 95%;
  background-color: #6c7073;
  margin-top: 10px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 2px rgba(51, 51, 51, 0.41);
}

.logoContainer:focus-visible {
  outline: lightgreen solid 2px;
}

.beatLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100;
}

.hamburger {
  display: none;
}

@media only screen and (max-width: 750px) {
  .shared-0-3-6 {
    height: 40px !important;
    font-size: 12px !important;
    width: 100% !important;
    padding: 10px !important;
  }
}
@media only screen and (max-width: 550px) {
  .shared-0-3-6 {
    padding: 5px !important;
    height: 35px !important;
    font-size: 10px !important;
  }
  .drop {
    margin-left: 75%;
  }
}
@media only screen and (max-width: 470px) {
  .shared-0-3-6 {
    padding-right: 10px !important;
    font-size: 8px !important;
  }
}
@media only screen and (max-width: 400px) {
  .drop {
    display: none;
  }
  .hamburger {
    position: absolute;
    margin-left: 80%;
    display: block;
  }
}
