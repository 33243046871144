/* stylelint-disable value-list-comma-newline-after */
/* stylelint-disable declaration-colon-newline-after */
/* stylelint-disable color-function-notation */
/* stylelint-disable indentation */
/* stylelint-disable selector-combinator-space-before */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable custom-property-pattern */

/* VARIABLES */

:root {
  --buttonBackground: #3299bb;
  --buttonShadow: gray;
  --header: #5c8d76;
  --accent: #e54b4b;
  --background: #e4e1db;
  --gray: #333;
  --button: #3b828f;
  --blog: #25a2b9;
  --screens: #b96c25;
  --whiteSmoke: #f5f5f5;
}

/* GENERAL */

html {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.main {
  background-color: var(--background);
}

.main-alt {
  background-color: var(--gray);
}

a {
  color: #1a1a1a;
  text-decoration: none;
  font-family: Mate, serif;
  font-size: 1rem;
  display: flex;
  gap: 5px;
}

.a-alt {
  color: white;
  text-decoration: none;
  font-family: Mate, serif;
  font-size: 1rem;
  display: flex;
  gap: 5px;
}

a:focus-visible {
  outline: lightgreen solid 2px;
}

.a:focus-visible {
  outline: lightgreen solid 2px;
}

img {
  border-radius: 5px;
}

#root > div > div.contact-wrapper > img {
  content-visibility: auto;
}

/* GRID */

.grid-wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(6, 197px);
  margin-top: 10px;
}

.grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* SCREENS */

.screen-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 50px;
}

.screen {
  width: 380px;
  height: 200px;
  background-color: var(--screens);
  border-radius: 15px;
  box-shadow: 0 0 6px 2px rgba(51, 51, 51, 91%);
}

.screen:focus-visible {
  outline: lightgreen solid 2px;
}

.button-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.button:focus-visible {
  outline: lightgreen solid 2px;
}

.MuiSwitch-root:focus-visible {
  outline: lightgreen solid 2px;
}

.image:hover {
  transform: scale(1.1);
}

.image:focus-visible {
  outline: lightgreen solid 2px;
}

h1 {
  font-family: Mate, serif;
  font-size: 2.5rem;
  text-align: center;
  color: var(--gray);
  letter-spacing: 0.05em;
}

.h1-alt {
  font-family: Mate, serif;
  font-size: 2.5rem;
  text-align: center;
  color: white;
  letter-spacing: 0.05em;
}

h2 {
  font-family: Mate, serif;
  color: var(--gray);
  letter-spacing: 0.05em;
  margin: auto;
  text-align: center;
}

.headers {
  font-family: Mate, serif;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: -50px;
  margin-left: -130px;
}

.chip-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 160px);
  padding-top: 15px;
}

/* .vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-family: "Mate SC", serif;
  letter-spacing: 0.65em;
  font-size: 1.3rem;
  margin-right: 10px;
  margin-left: -10px;
  margin-top: 10px;
  padding: 15px 5px 5px 5px;
} */

.contact-badges {
  margin-top: 3%;
  display: grid;
  grid-gap: 14%;
  grid-template-columns: repeat(3, 160px);
  margin-bottom: 6%;
}

.contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons {
  margin-bottom: 25%;
}

.icons:hover {
  transform: scale(1.5) rotate(-5deg);
}

ul {
  list-style-type: none;
}

#up > div > div > div.drop > div > div {
  padding: 0;
}

.dropdown-divider {
  margin: 0;
}

.dropdown-item {
  padding: 1rem 1.2rem;
}

.dropdown-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dropdown-item:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dropdown-item > a:active {
  outline: none;
}

#up > div > div > div.drop > div > div > a > a:hover {
  color: white;
}

.dropdown-item:active {
  background-color: var(--whiteSmoke);
}

.dropdown-menu {
  padding: 0;
  border-radius: 5px;
}

div.MuiChip-root {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
}

#root
  > div
  > div:nth-child(4)
  > div.timeline
  > div.chipWrapper
  > div
  > div
  > a {
  margin-left: 30px;
  background-color: var(--blog);
}

#root > div > div:nth-child(4) > div.timeline > div.chipWrapper > div > div {
  padding-bottom: 35px;
}

.p-rating {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  font-size: 32px;
}

.p-rating-star {
  background-color: transparent;
  border: transparent;
  margin: 0 2px;
  padding: 0;
  position: relative;
}

.MuiFormControlLabel-label {
  display: none;
}

.ant-progress-text {
  color: var(--gray);
}

.ant-progress-text-alt {
  color: red;
}

.MuiButton-label {
  color: #282828;
  font-family: Mate, serif;
  font-weight: 900;
}

.MuiTypography-colorError {
  color: white !important;
}

.projects {
  display: grid;
  grid-template-columns: repeat(4, 197px);
  grid-gap: 3%;
  margin-top: 30px;
  margin-bottom: 100px;
}

.oldwebsites {
  display: grid;
  grid-template-columns: repeat(3, 197px);
  grid-gap: 3%;
  margin-top: 30px;
  margin-bottom: 180px;
}

.allProjects {
  display: grid;
  grid-template-columns: repeat(4, 197px);
  grid-gap: 3%;
  margin-top: 30px;
  margin-bottom: 180px;
  margin-left: -50px;
}

.label-0-3-5 {
  font-family: Mate, serif;
}

.kbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 1.6;
  color: rgb(28 28 29);
  padding: 12px 16px;
  background: transparent;
  border-left: 2px solid transparent;
  cursor: pointer;
}

.kbar:first-child {
  margin-top: 10px;
}

.kbarContainer {
  max-width: 600px;
  width: 100%;
  background: rgb(252 252 252);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgb(0 0 0 / 20%);
  margin-top: 80px;
}

.activeBar {
  background-color: #f1f1f1;
  border-left: 2px solid #194d55;
}

.kbarInput {
  padding: 12px 18px;
  font-size: 1rem;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  width: 150%;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 5px;
  background: rgb(252 252 252);
}

.kbar span {
  background-color: rgba(0, 0, 0, 10%);
  border-radius: 3px;
  font-size: 1.5rem;
  padding: 4px 6px;
  font-family: monospace;
}

.kbar-item p {
  font-size: 0.8rem;
}

.kbar-item p::first-letter {
  text-transform: uppercase;
}

.commandPic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5%;
  margin-left: -4%;
}

.commandPicIcon {
  margin-top: 6%;
}

.commandPic span:first-of-type {
  margin-right: 10px;
  font-weight: bold;
}

.commandPic span:nth-of-type(2) {
  margin-left: 10px;
  font-weight: bold;
}

.commandPic span:nth-of-type(3) {
  font-size: 1.7rem;
  margin-left: 5%;
  font-family: Mate, serif;
}

@media only screen and (max-width: 1300px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 3%;
    grid-template-columns: repeat(6, 160px);
    margin-top: 1%;
    margin-left: -150px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  h1 {
    margin-bottom: -1%;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -60px;
    margin-bottom: -20px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(4, 200px);
    padding-top: 30px;
  }

  .button {
    font-size: 1.1rem !important;
  }

  .h1 {
    margin-bottom: 1%;
  }

  .devHashnode {
    margin-top: 15px;
  }

  div[id*="mantine"] {
    width: 180px;
  }

  .allProjects {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1200px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 3%;
    grid-template-columns: repeat(5, 180px);
    margin-top: 1%;
    margin-left: -100px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  h1 {
    margin-bottom: -1%;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -60px;
    margin-bottom: -20px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(4, 200px);
    padding-top: 30px;
  }

  .switch {
    margin-left: 15px;
  }

  .menu-d0-0-3-18 {
    margin-left: -30px;
  }

  div[id*="mantine"] {
    margin-left: -20%;
  }

  .allProjects {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1100px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 3%;
    grid-template-columns: repeat(5, 175px);
    margin-top: 1%;
    margin-left: -100px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  h1 {
    margin-bottom: -1%;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -60px;
    margin-bottom: -20px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(4, 180px);
    padding-top: 30px;
  }

  .switch {
    margin-left: 30px;
  }

  .menu-d0-0-3-18 {
    margin-left: -40px;
  }

  .allProjects {
    grid-template-columns: repeat(3, 1fr);
    margin-left: -30px;
  }
}

@media only screen and (max-width: 1000px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 2%;
    grid-template-columns: repeat(5, 160px);
    margin-top: 1%;
    margin-left: -60px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -60px;
    margin-bottom: -20px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(4, 170px);
    padding-top: 15px;
  }

  .switch {
    margin-left: 45px;
  }

  .logo {
    margin-left: 25px;
  }

  .contacts {
    margin-bottom: 2%;
  }

  .menu-d0-0-3-18 {
    width: 170px !important;
  }

  div[id*="mantine"] {
    margin-left: -25%;
    width: 170px;
  }

  .allProjects {
    grid-template-columns: repeat(3, 1fr);
    margin-left: -30px;
  }

  .projects {
    grid-template-columns: repeat(3, 197px);
  }
}

@media only screen and (max-width: 900px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 2%;
    grid-template-columns: repeat(4, 170px);
    margin-top: 1%;
    margin-left: -40px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -50px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 150px);
    padding-top: 15px;
  }

  .switch {
    margin-left: 10%;
  }

  .bubble {
    display: none !important;
  }

  .allProjects {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
  }

  .commandPic {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 2%;
    grid-template-columns: repeat(3, 180px);
    margin-top: 1%;
    margin-left: -10px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 8%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -50px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(3, 170px);
    padding-top: 15px;
  }

  .switch {
    margin-left: 7%;
  }

  .headerClip {
    width: 300px;
  }

  #root
    > div
    > div:nth-child(3)
    > div.timeline
    > div.chipWrapper
    > div
    > div
    > a {
    margin-left: 8.8%;
  }

  .devHashnode {
    font-size: 1.5rem;
  }

  .underlined {
    font-size: 1.5rem;
  }

  .menu-d0-0-3-18 {
    width: 160px !important;
  }

  div[id*="mantine"] {
    margin-left: -65%;
  }

  .allProjects {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
  }

  .projects {
    grid-template-columns: repeat(2, 197px);
  }

  .oldwebsites {
    grid-template-columns: repeat(2, 197px);
  }
}

@media only screen and (max-width: 700px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 2%;
    grid-template-columns: repeat(2, 200px);
    margin-top: 1%;
    margin-left: -10px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 18%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -50px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 140px);
    padding-top: 15px;
  }

  .switch {
    margin-left: -2%;
  }

  .headerClip {
    width: 250px;
  }

  .footer {
    padding: 0 10%;
  }

  .stats {
    padding-left: 10%;
  }

  .dropdown {
    margin-left: -2%;
  }

  #root
    > div
    > div:nth-child(3)
    > div.timeline
    > div.chipWrapper
    > div
    > div
    > a {
    margin-left: 13.8%;
  }

  .menu-d0-0-3-18 {
    width: 130px !important;
  }
}

@media only screen and (max-width: 600px) {
  .dropdown {
    margin-left: -20%;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 23%;
    justify-content: space-around;
    align-items: center;
  }

  .theme-btn {
    width: 10px;
  }

  .switch {
    margin-left: -12%;
  }

  .headerClip {
    width: 170px;
  }

  #root
    > div
    > div:nth-child(3)
    > div.timeline
    > div.chipWrapper
    > div
    > div
    > a {
    margin-left: 11%;
  }

  .menu-d0-0-3-18 {
    margin-left: -50px;
  }

  div[id*="mantine"] {
    margin-left: -80%;
    width: 160px;
  }
}

@media only screen and (max-width: 500px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 1%;
    grid-template-columns: repeat(3, 140px);
    margin-top: 1%;
    margin-left: -10px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  h1 {
    margin-top: 4%;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -30px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 130px);
    padding-top: 15px;
  }

  .dropdown {
    margin-left: -25%;
  }

  .switch {
    display: none;
  }

  .button {
    font-size: 1rem !important;
  }

  .contact-badges {
    margin-top: 3%;
    margin-left: 7%;
    display: grid;
    grid-gap: 8%;
    grid-template-columns: repeat(3, 140px);
    margin-bottom: 10%;
  }

  .theme-btn {
    display: none;
  }

  .logo {
    margin-left: -1px;
  }

  #root
    > div
    > div:nth-child(3)
    > div.timeline
    > div.chipWrapper
    > div
    > div
    > a {
    margin-top: 2%;
    margin-left: 8.8%;
  }

  .devHashnode {
    font-size: 1.2rem;
    margin-top: 25px;
  }

  .underlined {
    font-size: 1.2rem;
  }

  .menu-d0-0-3-18 {
    width: 120px !important;
  }

  div[id*="mantine"] {
    margin-left: -95%;
    width: 150px;
  }

  .allProjects {
    grid-template-columns: repeat(2, 167px);
  }

  .projects {
    grid-template-columns: repeat(2, 167px);
  }

  .oldwebsites {
    grid-template-columns: repeat(2, 167px);
  }

  .react-page-title {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 470px) {
  .grid-wrapper {
    display: grid;
    grid-gap: 2%;
    grid-template-columns: repeat(2, 150px);
    margin-top: 1%;
    margin-left: 10px;
  }

  .dropdown {
    margin-left: -30%;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 25%;
    justify-content: space-around;
    align-items: center;
  }

  .headers {
    display: none;
  }

  h1 {
    margin-top: 4%;
    margin-bottom: -4%;
  }

  .chipWrapper {
    width: 80%;
    margin-left: -30px;
  }

  .chip-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 100px);
    padding-top: 15px;
  }

  .switch {
    display: none;
  }

  .react-page-title {
    margin-bottom: 5%;
  }

  .projects {
    gap: 3%;
  }
}

@media only screen and (max-width: 420px) {
  .contact-badges {
    margin-top: 3%;
    display: grid;
    grid-gap: 6%;
    grid-template-columns: repeat(3, 120px);
    margin-bottom: 10%;
  }

  div[id*="mantine"] {
    margin-left: -110% !important;
  }
}

@media only screen and (max-width: 400px) {
  .timeline {
    display: flex;
    flex-direction: column;
    margin-top: 30%;
    justify-content: space-around;
    align-items: center;
  }

  .grid {
    margin-left: -4%;
  }

  .footer {
    width: 98%;
  }

  div[id*="mantine"] {
    margin-left: -260% !important;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @media only screen and (max-width: 1900px) {
    .drop {
      position: relative;
      margin-left: 80%;
    }

    .switch {
      margin-left: -40%;
    }

    .logo {
      margin-left: 370%;
    }

    .menu {
      display: flex;
      align-items: center;
    }

    .theme-btn {
      margin-left: 200px;
    }

    #root > div > div.uses > div {
      margin-right: 20px;
    }

    #root > div > div.stickyHeader > div > div > div.header > div {
      transform: none !important;
    }

    .icons.course-icons {
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .theme-btn {
      margin-left: 300px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .drop {
      position: relative;
      margin-left: 70%;
    }

    .logo {
      margin-left: 300%;
    }
  }

  @media only screen and (max-width: 1163px) {
    .switch {
      margin-left: -38%;
    }
  }

  @media only screen and (max-width: 1000px) {
    .theme-btn {
      margin-left: 350px;
    }
  }

  @media only screen and (max-width: 955px) {
    .logo {
      margin-left: 280%;
    }

    .switch {
      margin-left: -34%;
    }
  }

  @media only screen and (max-width: 900px) {
    .logo {
      margin-left: 250%;
    }

    .switch {
      display: flex;
    }

    .theme-btn {
      margin-left: 300px;
    }
  }

  @media only screen and (max-width: 780px) {
    .drop {
      position: relative;
      margin-left: 80%;
    }

    .logo {
      margin-left: 230%;
    }

    .switch {
      margin-left: -38%;
    }

    .theme-btn {
      margin-left: 250px;
    }
  }

  @media only screen and (max-width: 700px) {
    .theme-btn {
      margin-left: 240px;
    }

    .switch {
      margin-left: -41%;
    }

    .logo {
      margin-left: 210%;
    }
  }

  @media only screen and (max-width: 600px) {
    .theme-btn {
      margin-left: 200px;
    }

    .switch {
      margin-left: -47%;
    }

    .logo {
      margin-left: 180%;
    }
  }

  @media only screen and (max-width: 550px) {
    .theme-btn {
      margin-left: 190px;
    }

    .switch {
      margin-left: -49%;
    }

    .logo {
      margin-left: 170%;
    }

    .headerClip {
      width: 140px;
    }
  }

  @media only screen and (max-width: 470px) {
    .theme-btn {
      margin-left: 180px;
    }

    .switch {
      margin-left: -52%;
    }

    .logo {
      margin-left: 160%;
    }

    .headerClip {
      width: 100px;
    }
  }

  @media only screen and (max-width: 430px) {
    .theme-btn {
      margin-left: 170px;
    }

    .switch {
      display: none;
    }

    .headerClip {
      display: none;
    }

    .logo {
      margin-left: 130%;
    }

    .grid {
      margin-left: -6%;
    }
  }

  @media only screen and (max-width: 400px) {
    .logo {
      margin-left: 10%;
    }

    .grid {
      margin-left: -6%;
    }

    .hamburger {
      position: absolute;
      margin-left: 40%;
    }
  }
}

@font-face {
  font-family: Mate;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/mate/v9/m8JdjftRd7WZ6zq2W6fReLQ.woff2")
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
