.dropDownMenuItem:not(:last-child) {
  border-bottom: 1px solid rgb(233, 236, 239) !important;
}
.dropDownMenuItem:not(:first-child) {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.dropdownMenuButton:hover {
  color: var(--whiteSmoke);
  background-color: #537f69;
  border: 1px solid var(--whiteSmoke);
}
